import DigitalSubscriptionDetailsThemeBase from 'base/components/standalone/SubscriptionDetails/Digital/theme';

import { tw } from '@/utils/tw';

const DigitalSubscriptionDetailsTheme = tw.theme({
  extend: DigitalSubscriptionDetailsThemeBase,
  slots: {
    headline: ['text-headline-md', 'mb-1.5'],
    description: ['text-content-sm', 'mb-6'],
    detailsList: ['mb-8', 'md:flex', 'md:justify-between'],
    detail: ['text-content-sm', 'mb-3'],
    buttonGroup: ['flex', 'flex-col', 'md:flex-row', 'gap-3', 'mb-4'],
    contactInfo: ['[&>a]:text-summer-red-600', 'text-content-sm'],
  },
});

export default Object.assign(DigitalSubscriptionDetailsTheme, {
  Button: DigitalSubscriptionDetailsThemeBase.Button,
  History: DigitalSubscriptionDetailsThemeBase.History,
  Dialog: DigitalSubscriptionDetailsThemeBase.Dialog,
});
